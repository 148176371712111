.ubf-contact {
	font-style: normal;

	a:not(.ubf-button) {
		@include ubf-link();
		font-weight: normal!important;
	}

	a.ubf-accordion__togglerLink  {
		text-decoration: none!important;
		font-weight: bold!important;
	}

	.ubf-contentBlock--accordion dt.ubf-accordion__toggler {
		display: inline-flex;
	}

	&__button {
		margin-bottom: $base;
	}

	&__img {
		@include media($largeLayoutBreakpoint) {
			float:right;
			margin: 0rem 0 2rem 2rem;
			max-width:180px;
		}
		display:block;
		width:100%;
		margin-top: $base;
	}


	&__details {
		margin-top: $base3;
		width:auto!important;
	}


	&__detailName {
		display: inline-block;
		margin: 0;
		width:36%!important;
		&::after {
			content: ':';
		}
	}


	&__detailValue {
		display: inline-block;
		margin: 0;
		width:64%!important;
	}

	&__pevzButton {
		margin-top: $base3;
	}

	&__name {
		@include ubf-font(h);

		.ubf-address__title + & {
			margin-top: $base;
		}
	}

	&__role {
		@include ubf-font(base);
		font-weight: bold;
		margin-top: $base;
	}


	&__title {
		@include ubf-font(h3);
	}


	// &__text {
	// 	@include ubf-font(base);

	// 	.ubf-preview__title + & {
	// 		margin-top: $base / 2;

	// 		@include media($largeLayoutBreakpoint) {
	// 			margin-top: $base;
	// 		}
	// 	}
	// }
}
