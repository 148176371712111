.ubf-previewsSliderNavigation {

  	position: absolute;
  	width: 100%;

  	top: calc(33% - #{$base4} * 0.5);

	.ubf-contentBlock--previewsExtended & {
	  top: calc(25% - #{$base4} * 0.5);
	}

	&__icon {
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		pointer-events: none;

		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorBase;
		}

		.ubf-previewsSliderNavigation__item--prev & {
			transform: rotate(180deg);
		}

		.ubf-previewsSliderNavigation__item--next & {
		}

		.ubf-previewsSliderNavigation__link.ubf-js-current & {
			background-color: currentColor;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__item {
		line-height: 0;
		pointer-events: none;
	  	width: $base4;
		height: $base4;
	  	box-sizing: content-box;
		margin: 0 - $base1;

		border: 2px solid $colorBgBase;
		@include ease(border-color, $duration2);
	  	&:hover {
		  border-color: $colorBase;
		}

		&--prev,
		&--next {
			html.no-js & {
				display: none;
			}
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		pointer-events: none;
	}


	&__link {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: $base4;
		pointer-events: all;
		width: 100%;
	  	@include ubf-highlightColor(background-color);

		.ubf-previewsSliderNavigation__item--next & {
			justify-content: center;
		}

		&:not(:hover):focus {
			outline: $focusOutlineStyle $colorInverted 1px;
			outline-offset: 1px;
		}
	  	&.ubf-js-disabled {
		  pointer-events: none;
			background-color: $colorBgSubMenu;
		}

	}
}
