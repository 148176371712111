.ubf-previews {
	@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: right);


	@include media($largeLayoutBreakpoint) {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: right);
	}

	&--slider {
		--itemsPerSlide: 1;
		flex-wrap: nowrap;
		overflow: hidden;
	  	user-select: none;

		@include media($largeLayoutBreakpoint) {
			--itemsPerSlide: 3;
			flex-wrap: nowrap;
			overflow: hidden;
		}

		.ubf-contentBlock--previewsExtended & {
			@include media($largeLayoutBreakpoint) {
				--itemsPerSlide: 4;
			}
		}

		.ubf-contentBlock--previewsReduced & {
			@include media($largeLayoutBreakpoint) {
				--itemsPerSlide: 2;
			}

			.ubf-body:not(.ubf-body--internal) .ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					--itemsPerSlide: 3;
				}
			}
		}


		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 2px;
			z-index: 10;
			background-color: $colorBgBase;
			height: 100%;
		}
	}


	&__preview {
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 3, $gridGutterLarge);
		}

		.ubf-contentBlock--previewsExtended & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 4, $gridGutterLarge);
			}
		}

		.ubf-contentBlock--previewsReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 2, $gridGutterLarge);
			}

			.ubf-body:not(.ubf-body--internal) .ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(1, 3, $gridGutterLarge);
				}
			}
		}

		.ubf-previews--slider & {
			@include ease(transform, $duration4);
			position: relative;
			transform: translateX(calc(-100% * var(--sliderItemIndex, 0))) translateX(calc(-#{$gridGutterSmall} * var(--sliderItemIndex, 0)));

			@include media($largeLayoutBreakpoint) {
				transform: translateX(calc(-100% * var(--sliderItemIndex, 0))) translateX(calc(-#{$gridGutterLarge} * var(--sliderItemIndex, 0)));
			}
		}
	}
}
